<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" content="收费"> </el-page-header>
          <div>
            <el-button type="primary" size="small" @click="toRefund">确定</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <div class="title" v-if="orderGrouped[2].length > 0">检查治疗项</div>
          <el-table :data="orderGrouped[2]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[2].length > 0">
            <el-table-column prop="entityName" label="检查名称"></el-table-column>
            <el-table-column prop="money" label="单价" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="danger optionBtn" @click="toDel(scope.row, scope.$index, 2)">删除</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[1].length > 0">处方药<span style="color: red;font-size: 13px;">（处方不修改）</span></div>
          <el-table :data="orderGrouped[1]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[1].length > 0">
            <el-table-column prop="prescriptionType" label="处方类型">
              <template slot-scope="scope">
                <p v-if="scope.row.prescriptionType == 0">方剂处方</p>
                <p v-if="scope.row.prescriptionType == 1">成药处方</p>
                <p v-if="scope.row.prescriptionType == 2">输液处方</p>
              </template>
            </el-table-column>
            <el-table-column prop="entityName" label="药品名称" align="center"></el-table-column>
            <el-table-column prop="specification" label="规格" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">
                  {{ scope.row.specification }}/{{scope.row.specificationUnit }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="money" label="单价" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[0].length > 0">直接售药</div>
          <el-table :data="orderGrouped[0]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[0].length > 0">
            <el-table-column prop="entityName" label="药品名称"></el-table-column>
            <el-table-column prop="specification" label="规格" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">
                  {{ scope.row.specification }}/{{scope.row.specificationUnit }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="money" label="单价" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="danger optionBtn" @click="toDel(scope.row, scope.$index, 0)">删除</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[3].length > 0">附加收费</div>
          <el-table :data="orderGrouped[3]" style="width: 100%;" :header-cell-style="{ background: '#F5F7FC' }"
            v-if="orderGrouped[3].length > 0">
            <el-table-column prop="entityName" label="费用名称"></el-table-column>
            <el-table-column prop="countMoney" label="金额（元）" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="danger optionBtn" @click="toDel(scope.row, scope.$index, 3)">删除</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="numDiv flex-end">
            <div class="">总金额：￥{{ form.totalMoney ? form.totalMoney.toFixed(2) : '0.00' }} <span class="num1">-</span>
              优惠金额：</div>
            <div class="inputNum">￥<el-input-number v-model="form.discountsMoney" size="small" controls-position="right"
                :precision="2" :step="1" :min="0" :max="form.totalMoney" class="num2"></el-input-number></div>
            <div class="num1"> = 应收金额:</div>
            <div class="inputNum" style="margin-right: 50px;">￥<span style="font-size: 25px;">{{ (form.totalMoney -
              form.discountsMoney).toFixed(2) }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="收费" :visible.sync="setDialog" width="30%">
      <el-form class="dialog-form" :model="form" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="应收金额" style="color: #FF0000;" class="numT">
          ￥<span style="font-size: 25px;">{{ (form.totalMoney - form.discountsMoney).toFixed(2) }}</span>
        </el-form-item>
        <el-form-item label="挂账">
          <el-switch v-model="form.credit" @change="handleChange"></el-switch>
        </el-form-item>
        <template v-if="form.credit">
          <el-form-item label="姓名" prop="debtUserName">
            <el-input v-model="form.debtUserName" style="width: 80%;" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="debtUserPhone" style="width: 80%;">
            <el-input v-model="form.debtUserPhone" clearable></el-input>
          </el-form-item>
          <el-form-item label="挂账金额" prop="debtMoney">
            <el-input-number v-model="form.debtMoney" size="small" controls-position="right" :precision="2" :step="1"
              :min="1" class="leftInput" @change="checkDebtMoney"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="付款方式">
          <el-radio-group v-model="form.payWay">
            <el-radio :label="0">现金</el-radio>
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="2">支付宝</el-radio>
            <el-radio :label="3">银行卡</el-radio>
            <el-radio :label="5">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="付款金额" prop="payment">
          <el-input-number v-model="form.payment" size="small" controls-position="right" :precision="2" :step="1"
            class="leftInput" @change="checkMoney"></el-input-number>
        </el-form-item>
        <el-form-item label="找零">
          {{ form.change ? form.change.toFixed(2) : '0.00' }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      tableData: [],
      detailInfo: {
        gender: 0,
      },
      userInfo: {
      },
      setDialog: false,
      setRefundDialog: false,
      formLabelWidth: '100px',
      charge: {
        num: 12,
        type: 1,
        payNum: 12,
        change: 0
      },
      refund: {
        id: parseInt(this.$route.query.orderId),
        totalMoney: null,
        details: [],
        payments: [{
          payWay: 0,
          payMoney: 0
        }]
      },
      form: {
        patientId: 0,
        discountsMoney: 0,//优惠金额
        totalMoney: 0,//总金额
        userPayMoney: 0,//相对订单用户实付金额
        details: [
          {
            orderTyp: 0,//0直接售药 1处方 2检查治疗项 3附加收费
            entityId: 0,
            entityName: null,
            purchasePrice: 0,
            price: 0,
            money: null,
            amount: 1,
            countMoney: null,
            productFactory: null,
            specification: null,
          },
        ],
        credit: false,
        payWay: 0,//0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        debtUserName: null,
        debtUserPhone: null,
        debtMoney: 0,//挂账总金额
        payment: null,//输入金额
        payMoney: 0,//支付金额
        change: 0//找零
      },
      orderId: this.$route.query.orderId,
      selectDetail: [],
      orderGrouped: { 0: [], 1: [], 2: [], 3: [] },
      multipleSelection0: [],
      multipleSelection1: [],
      multipleSelection2: [],
      multipleSelection3: [],
      rules: {
        debtUserName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        debtUserPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号", trigger: "blur" }
        ],
        debtMoney: [{ required: true, message: '请输入挂账金额', trigger: 'blur' }],
        payment: [{ required: true, message: '请输入付款金额', trigger: 'blur' }],
      },
    }
  },
  watch: {
    'form': {
      handler (newValue) {
        let totalUserPayMoney = 0
        this.form.details.forEach(item => {
          item.countMoney = item.money * item.amount
          totalUserPayMoney = totalUserPayMoney + item.countMoney
        })
        this.form.totalMoney = totalUserPayMoney
        this.form.userPayMoney = totalUserPayMoney
      },
      deep: true
    }
  },
  mounted () {
    this.drugRefundDetail()
  },
  methods: {
    SaveBtn (formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            background: 'rgba(0, 0, 0, 0.5)'
          })
          _this.form.payMoney = this.form.totalMoney - this.form.discountsMoney - this.form.debtMoney
          _this.OutpatientDomain.DrugSell(_this.form,
            function (data) {
              loading.close()
              _this.$message({
                message: '收费成功',
                type: 'success',
              })
              _this.setDialog = false
              _this.$router.go(-1)
            },
            function (error) {
              loading.close()
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    checkDebtMoney () {
      this.form.payment = this.form.totalMoney - this.form.discountsMoney - this.form.debtMoney
      this.form.change = 0
    },
    checkMoney () {
      if (this.form.credit) {
        let payMoney = this.form.totalMoney - this.form.discountsMoney - this.form.debtMoney
        if (this.form.payment < payMoney) {
          this.$message({
            message: `付款金额不能小于${payMoney}`,
            type: 'error',
          })
          this.form.payment = undefined
          return
        } else {
          this.form.change = (this.form.payment + this.form.debtMoney) - (this.form.totalMoney - this.form.discountsMoney)
        }
      } else {
        if (this.form.payment < this.form.userPayMoney) {
          this.$message({
            message: `付款金额不能小于${this.form.userPayMoney}`,
            type: 'error',
          })
          this.form.payment = undefined
          return
        } else {
          this.form.change = this.form.payment - (this.form.totalMoney - this.form.discountsMoney)
        }
      }

    },
    handleChange () {
      this.form.debtUserName = null
      this.form.debtUserPhone = null
      this.form.debtMoney = undefined
      this.form.change = null
      if (!this.form.credit) {
        this.form.payment = this.form.totalMoney - this.form.discountsMoney
        this.form.debtMoney = 0
      }
    },
    CloseBtn (formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
    toDel (item, index, dataIndex) {
      this.orderGrouped[dataIndex].splice(index, 1)
      this.form.details = [...this.orderGrouped[0], ...this.orderGrouped[1], ...this.orderGrouped[2], ...this.orderGrouped[3],]
    },
    /**
    * 已退费费详情
    */
    drugRefundDetail () {
      var _this = this
      _this.OutpatientDomain.DrugRefundDetail(this.orderId,
        function (data) {
          _this.detailInfo = data.data
          //orderType 0直接售药 1处方 2检查治疗项 3附加收费
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [] }
          if (_this.detailInfo.details.length > 0) {
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || []
              rest.orderType = orderType
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter(i => i.id == rest.entityId)
                if (drugOrg[0]) {
                  rest.specification = drugOrg[0].mzDrug.specification
                  rest.specificationUnit = drugOrg[0].mzDrug.specificationUnit
                  rest.productFactory = drugOrg[0].mzDrug.productFactory
                  rest.isSeparate = drugOrg[0].isSeparate
                  rest.separateNumber = drugOrg[0].separateNumber
                  rest.separateNumberUnit = drugOrg[0].separateNumberUnit
                }
              }
              _this.orderGrouped[orderType].push(rest)
            })
            _this.form.details = _this.detailInfo.details
            _this.form.discountsMoney = _this.detailInfo.discountsMoney
            _this.form.patientId = _this.detailInfo.patientId
            _this.form.patientName = _this.detailInfo.patientName
            _this.form.doctorId = _this.detailInfo.doctorId
            _this.form.doctorName = _this.detailInfo.doctorName
            _this.form.patientDiagnoseId = _this.detailInfo.patientDiagnoseId
            _this.form.diagnoseResult = _this.detailInfo.diagnoseResult
            _this.form.diagnoseType = _this.detailInfo.diagnoseType
            _this.form.diagnoseType = _this.detailInfo.diagnoseType
            _this.form.userId = _this.detailInfo.userId
            _this.form.userName = _this.detailInfo.userName
            _this.form.userId = _this.detailInfo.userId
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    toRefund () {
      this.form.payment = this.form.totalMoney - this.form.discountsMoney
      this.form.payMoney = this.form.totalMoney - this.form.discountsMoney
      this.form.change = null
      this.setDialog = true
      this.form.credit = false
    },
    goBack () {
      this.$router.push('./ChargedList')
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {

    padding: 0 40px;

    .top-info {
      .t1 {
        margin-right: 30px;
      }

      .t2 {
        color: #999999;
      }

      .right-i {
        margin-right: 10%;
      }

      .userName {
        margin-left: 10px;
      }

      .info-div {
        margin-top: 10px;
      }
    }

    .title {
      height: 60px;
      line-height: 60px;
    }

    ::v-deep .el-table__row>td {
      /* 去除表格线 */
      // border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
      padding: 10px 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    .el-form-item .el-form-item {
      margin-bottom: 20px;
    }

    .leftInput {
      width: 100%;
      position: relative;
    }

    .rightTip {
      position: absolute;

      top: 0;
      z-index: 2;
      color: #999999;
    }

    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }

    .numDiv {
      padding: 15px;
      background: #FFF7F7;
      border: 1px solid #F4D3D3;
      border-radius: 8px;
      margin: 20px 0;

      .num1 {
        margin: 0 10px;
      }

      .num2 {
        color: #FF0000;
      }

      ::v-deep .num2 .el-input__inner {
        color: #FF0000;
      }

      .inputNum {
        color: #FF0000;
      }
    }

    .numDiv2 {
      background: #FAFAFA;
      border: 1px solid #E6E6E6;

      .right-num {
        // margin-right: 10%;
      }

      .center-num {
        // margin: 0 20px;
      }

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #999999;
      }

      .t3 {
        color: #FE0000;
      }

      .num1 {
        margin: 0 10px;
      }

      ::v-deep .num2 .el-input__inner {
        color: #FF0000;
      }

      .inputNum {
        color: #FF0000;
      }
    }

    .infoDiv {
      // margin: 0 20px;

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #5C83F2
      }
    }
  }
}
</style>
